import localforage from "localforage";
export default {

    /**
     * 保存数据到localforage
     */
    saveItemToLocalforageByKey(key, value, callBack){
        if(!key || key.length == 0){
            return;
        }
        if(!value){
            return;
        }
        localforage.setItem(key,value).then(res=>{
            if(callBack){
                callBack(res);
            }
        });
    },

    /**
     * 获取数据到localforage
     */
    getItemToLocalforageByKey(key,callBack){
        if(!key || key.length == 0){
            return;
        }
        localforage.getItem(key).then(res=>{
            if(callBack){
                callBack(res);
            }
        });
    },
    /**
     * 移除数据从localforage
     */
    removeItemFromLocalforage(key, callBack){
        if(!key || key.length == 0){
            return;
        }
         localforage.removeItem(key).then(function () {
             if(callBack){
                 callBack();
             }
         });
    },

    /**
     * 清除数据
     */
    clearLocalForage(){
        localforage.clear();
    },

    /**
     * 获取所有key
     */
    getAllKey(callBack){
        localforage.keys().then((keys)=>{
             if(callBack){
                 callBack(keys);
             }
        });
    }




}
