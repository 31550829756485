
import storex from "../store/store"
import LocalForageDBManger from "./LocalForageDBManger";
import LocalStorageDBManger from "./LocalStorageDBManger";

/**
 * 培训信息保存
 */
export default {
    keys:{
        //当前选择的计划 - object
        OFFLINE_SELECT_CURRENT_PLAN_OBJECT : "OFFLINE_SELECT_CURRENT_PLAN_OBJECT",
    },

    //==================================================计划============================================//


    /**
     * 保存选择的计划
     */
    saveCurrentPlanItemToDB(planItem){
        if(planItem == null){
            console.warn('保存的计划不能为null');
            return;
        }
        const userId = storex.getters.userId;

        // 保存计划先移除计划
        const that = this;
        this.removePlanItemFromDB(function () {
            const key =  userId + that.keys.OFFLINE_SELECT_CURRENT_PLAN_OBJECT;
            if(window.localStorage){
                LocalStorageDBManger.saveDataToLocalStorage(key, planItem);
            }else {
                LocalForageDBManger.saveItemToLocalforageByKey(key, planItem);
            }
        });
    },
    /**
     * 获取选择的计划
     */
    getCurrentPlanItemFromDB(callBack){
        const userId = storex.getters.userId;
        const key =  userId + this.keys.OFFLINE_SELECT_CURRENT_PLAN_OBJECT;
        if(window.localStorage){
            const item = LocalStorageDBManger.getObjectWithLocalStorage(key);
            if(callBack){
                callBack(item);
            }
        }else {
            const item = LocalForageDBManger.getItemToLocalforageByKey(key);
            if(callBack){
                callBack(item);
            }
        }

    },
    /**
     * 重置保存的计划
     */
    removePlanItemFromDB(callBack){

        if(window.localStorage){
            const keyList = LocalStorageDBManger.getStorageAllKeys();
            for (let i = 0; i < keyList.length; i++) {
                const key = keyList[i];
                if(key.indexOf(this.keys.OFFLINE_SELECT_CURRENT_PLAN_OBJECT) != -1){
                    LocalStorageDBManger.removeDataWithLocalStorage(key);
                }
            }
            if(callBack){
                callBack();
            }
        }else {
            LocalForageDBManger.getAllKey(function (keyList) {
                for (let i = 0; i < keyList.length; i++) {
                    const key = keyList[i];
                    if(key.indexOf(this.keys.OFFLINE_SELECT_CURRENT_PLAN_OBJECT) != -1){
                        LocalForageDBManger.removeItemFromLocalforage(key);
                    }
                }
                if(callBack){
                    callBack();
                }
            });

        }

    },
}
