import hoursApi from "../api/hours/hoursApi";
import storex from "../store/store";
import LocalForageDBManger from "../db/LocalForageDBManger";
import { HudGlobal } from "./HudGlobal";
import LocalStorageDBManger from "../db/LocalStorageDBManger";
import StoreTrainDBManger from "../db/StoreTrainDBManger";

/**
 * 加载现场计划的相关参数, 刷新页面需要重新获取参数,
 * 在需要的每个页面,调用init()方法即可, 会自行判定是否加载了参数
 */
export default {
    OFFLINE_PLAN_PARAM: "OFFLINE_PLAN_PARAM",

    params: {
        // 是否使用人脸认证(所有) 0不开启, 1(默认)开启
        faceAuthEnable: 1,

        // 是否开启人脸比对入口(PC拍照) 0不开启,?1(默认)开启
        faceLiveDetectionPc: 1,

        // 是否开启人脸比对入口(手机APP) 0不开启,?1(默认)开启
        faceLiveDetectionApp: 1,

        // 是否管理员人脸认证后进入培训0不需要, 1(默认)需要认证
        offlineStartFaceAuthEnable: 1,

        // 视频进度条是否可拖动 0(默认)不可拖动, 1可拖动
        videoProcessBarDragEnable: 0,

        // 允许视频完成误差时长(秒) 60(单位秒)
        videoUnfinishedTime: 60,

        // 是否可播放已完成学习的视频 0不能播放, 1(默认)能播放
        offlineVideoFinishedReplayEnable: 1,

        // 是否自动播放下一个视频(PC端) 0不能播放, 1(默认)能播放
        videoPcAutoplayNext: 1,

        // 多个视频是否必须按顺序学习 0(默认)可随机学习, 1必须顺序学习
        videoPlayOrder: 0,

        // 是否暂停计时(视频暂停) 0不暂停计时, 1(默认)暂停计时
        videoStopTimerWithVideoPause: 1,

        // 是否暂停计时(焦点离开页面) 0不暂停计时, 1(默认)暂停计时
        videoStopTimerWithLeavePage: 1,

        // 现场培训记录是否必须上传 0不是, 1(默认)必须上传
        offlineLogMustRecord: 1,

        // 现场培训上传自有课件进行培训 0(默认)不可以, 1可以
        offlineCourseUserUploadEnable: 0,

        // 现场培训监督人多身份同一个人是否能合并签到 0:否, 1(默认)是
        offlineLeaderJoinSignEnable: 1,

        // 学时记录方式 0按秒随时记录即有效(默认),1抽验后学时才有效(需要签退)
        studyTimeType: 0,

        // 安全管理员是否需要签到 offline_admin_safe_signin_enable 0不需要, 1(默认)需要签到
        offlineAdminSafeSigninEnable: 1,

        // 考核人员是否需要签到 offline_assessors_signin_enable 0(默认)不需要, 1需要签到
        offlineAssessorsSigninEnable: 1,

        // 是否管理员人脸认证后进入培训（0不需要, 1(默认)）
        offlineLeaderSigninBeforeStudent: 1,

        // 监督人是否需要签到 offline_leader_signin_enable 0不需要, 1(默认)需要签到
        offlineLeaderSigninEnable: 1,

        // 授课人是否需要签到, 0不需要, 1(默认)需要签到
        offlineLecturerSigninEnable: 0,

        // 安全管理员签退是否强制签退未签退学员  offlineAdminSignoutHoursEnable  1 0:否, 1(默认)是
        offlineAdminSignoutHoursEnable: 1,

        // 考核人员签退是否强制签退未签退学员  offlineAssessorsSignoutHoursEnable 0:否, 1(默认)是
        offlineAssessorsSignoutHoursEnable: 1,

        // 授课人签退是否强制签退未签退学员  offlineLecturerSignoutHoursEnable 0:否, 1(默认)是
        offlineLecturerSignoutHoursEnable: 0,

        // 是否允许播放界面抽验,（0（默认）可以 1不可以）
        offlinePlayerPageAuth: 0,

        // 是否允许播放界面签到（0（默认）可以 1不可以）
        offlinePlayerPageSignin: 0,

        // 是否允许播放界面签退（0（默认）可以 1不可以）
        offlinePlayerPageSignout: 0,

        // 抽验是否强制跳转界面(0强制跳转(需要暂停计时) 1不强制(不暂停计时) )
        offlineSkipPlayerPageAuth: 1,

        // 课件进度提交间隔时间秒 60
        studyCourseProgressCommitDuration: 60,

        // 是否暂停计时(抽验) 0不暂停计时, 1(默认)暂停计时
        studyOfflineSpotCheckTimePause: 1,

        // ============= 现场不需要用的
        // 签退是否需要人脸验证 0不需要, 1(默认)需要
        signOutFaceAuth: 1,

        // 是否启动计时(练习)0不启用, 1(默认)启用
        studyTimePractiseEnable: 1,

        // 视频播放结束后是否需要练习题 0不是, 1(默认)必须练习
        videoFinishedPractiseEnable: 1,

        // 多个视频播放开始前是否要人像验证 0(默认)不验证, 1必须验证
        videoPlayFaceVerifyEnable: 0,

        // 是否启用活体检测(不限使用) 0不启用, 1(默认)启动活体检测
        faceLiveDetectionEnable: 1,

        // 是否启用活体检测(注册)0不启用, 1(默认)启动活体检测
        faceLiveDetectionRegEnable: 1,

        // 是否启用活体检测(考试)0不启用, 1(默认)启动活体检测
        faceLiveDetectionExamEnable: 1,

        // 是否启用活体检测(网络培训)0不启用, 1(默认)启动活体检测
        faceLiveDetectionTrainEnable: 1,

        // 是否启用活体检测(现场学习)0不启用, 1(默认)启动活体检测)
        faceLiveDetectionOfflineEnable: 1,

        // 活体检测动作间隔时间(秒) 10
        faceLiveDetectionCheckDuration: 10,

        // 活体检测动作检测个数(个)
        faceLiveDetectionCheckCount: 2,

        // 是否自动播放下一个视频(APP端) 0不能播放, 1(默认)能播放
        videoAppAutoplayNext: 1,

        // 网络考试是否需要抽验0否, 1(默认)是
        onlineExamAuthEnable: 1,
        // 网络考试抽验间隔时间,秒
        onlineExamAuthDuration: 100,
        // 网络考试最低交卷时长，秒
        onlineExamSubmitDuration: 100,
        // 网络考试是否需要人脸认证0否, 1(默认)是
        onlineExamFaceAuthEnable: 1,
        // 网络考试交卷是否需要人脸认证0否, 1(默认)是
        onlineExamSubmitFaceAuthEnable: 1,
        // 移动端是否能考试0否, 1(默认)是
        appExamEnable: 1,

        // 现场考试是否需要抽验0否, 1(默认)是
        offlineExamAuthEnable: 1,
        // 现场考试抽验间隔时间,秒
        offlineExamAuthDuration: 20,
        // 现场考试最低交卷时长，秒
        offlineExamSubmitDuration: 20,
        // 现场考试是否需要人脸认证0否, 1(默认)是
        offlineExamFaceAuthEnable: 20,
        // 现场考试交卷是否需要人脸认证0否, 1(默认)是
        offlineExamSubmitFaceAuthEnable: 20,

        pcStudyValidateVoice: 0, // 现场学习是否开始振动/语音

        isOpenStudyAudio: false, // 是否打开语言
    },
    // 是否已经从后台加载了参数
    isLoaded: false,
    // 从后台加载参数, 不在意是否先回来的话, 可以直接调用, 需要参数回来再做其它的,则可以在回调中处理
    init(planId, fun) {
        if (this.isLoaded) {
            console.log("已经加载了现场学习参数, 不会再加载", this.params);
            // 已经加载了参数, 不会再加载
            if (fun) {
                fun();
            }
            return false;
        }

        if (!planId) {
            console.error("没有planId去查询参数!");
            return;
        }

        // trainingId orgId dutyCode
        let params = {};
        params.planId = planId;

        let _this = this;
        hoursApi.getOfflineParams(params).then((res) => {
            if (res.success) {
                let data = res.data;
                Object.assign(_this.params, data);
                console.log("现场培训所有参数:", _this.params);

                _this.params.isOpenStudyAudio = data.pcStudyValidateVoice == 1;
                _this.isLoaded = true;
                // 保存参数

                _this.savePlanParamConfigByPlanIdToDB(planId, _this.params);
            } else {
                // 没有找到参数
            }

            if (fun) {
                fun();
            }
        });
    },

    /**
     * 初始化计划参数
     */
    initPlanParam({ planId, checkPlanValidFlag = true }, callBack) {
        if (!planId) {
            console.error("没有planId去查询参数!");
            return;
        }
        let params = {};
        params.planId = planId;
        params.checkPlanValidFlag = checkPlanValidFlag;
        const that = this;
        hoursApi.getOfflineParams(params).then((res) => {
            if (res.success) {
                let data = res.data;
                Object.assign(that.params, data);

                // 自定义参数
                that.params.isOpenStudyAudio = data.pcStudyValidateVoice == 1;
                console.log("现场培训所有参数:", that.params);
                // 保存参数

                that.savePlanParamConfigByPlanIdToDB(planId, that.params);
                if (callBack) {
                    callBack(that.params);
                }
            } else {
                // 没有找到参数
                HudGlobal.showWarningWithMessage(res.description);
            }
        });
    },

    /**
     * @description: 修改计划参数
     * @param {*} mdData
     * @return {*}
     */
    modifyOfflineParams(planId, mdData = {}) {
        mdData && Object.assign(this.params, mdData);
        if (mdData) {
            this.getPlanParamConfigByPlanIdFromDB(planId, (item) => {
                Object.assign(item, mdData);

                this.savePlanParamConfigByPlanIdToDB(planId, item);
            });
        }
    },

    /**
     * 获取计划参数配置
     */
    getCurretPlanParamConfigFromDB(callBack) {
        // 获取计划
        const that = this;
        StoreTrainDBManger.getCurrentPlanItemFromDB(function(planItem) {
            if (planItem != null) {
                const planId = planItem.planId;
                that.getPlanParamConfigByPlanIdFromDB(planId, callBack);
            }
        });
    },

    /**
     * 获取计划参数配置
     */
    getPlanParamConfigByPlanIdFromDB(planId, callBack) {
        if (!planId) {
            console.error("没有planId去查询参数!");
            return;
        }
        const userId = storex.getters.userId;
        const key = userId + planId + this.OFFLINE_PLAN_PARAM;

        if (window.localStorage) {
            const item = LocalStorageDBManger.getObjectWithLocalStorage(key);
            if (callBack) {
                callBack(item);
            }
        } else {
            const item = LocalForageDBManger.getItemToLocalforageByKey(key);
            if (callBack) {
                callBack(item);
            }
        }
    },
    /**
     * 保存计划参数配置
     */
    savePlanParamConfigByPlanIdToDB(planId, planParam) {
        if (!planId) {
            console.error("没有planId去查询参数!");
            return;
        }
        if (!planParam) {
            console.error("保存计划数据不能为空");
            return;
        }
        const userId = storex.getters.userId;
        const key = userId + planId + this.OFFLINE_PLAN_PARAM;
        this.removePlanParamConfig(function() {
            if (window.localStorage) {
                LocalStorageDBManger.saveDataToLocalStorage(key, planParam);
            } else {
                LocalForageDBManger.saveItemToLocalforageByKey(key, planParam);
            }
        });
    },
    /**
     * 移除计划参数配置
     */
    removePlanParamConfig(callBack) {
        if (window.localStorage) {
            const keyList = LocalStorageDBManger.getStorageAllKeys();
            for (let i = 0; i < keyList.length; i++) {
                const key = keyList[i];
                if (key.indexOf(this.OFFLINE_PLAN_PARAM) != -1) {
                    LocalStorageDBManger.removeDataWithLocalStorage(key);
                }
            }
            if (callBack) {
                callBack();
            }
        } else {
            LocalForageDBManger.getAllKey(function(keyList) {
                for (let i = 0; i < keyList.length; i++) {
                    const key = keyList[i];
                    if (key.indexOf(this.OFFLINE_PLAN_PARAM) != -1) {
                        LocalForageDBManger.removeItemFromLocalforage(key);
                    }
                }
                if (callBack) {
                    callBack();
                }
            });
        }
    },
};
