import http from "../util/http/studentHttp";
const baseUrl = process.env.VUE_APP_API_BASE_URL;
export default {
    // 查询计划列表
    getPage: function(params) {
        return http.get(baseUrl + "/offline/train/page", params);
    },

    /**
     * 密码验证
     */
    passwdVerity(params) {
        return http.post(baseUrl + "/api/student/passwd/verity", params);
    },
    /**
     * 查询计划课件列表 - 新接口
     */
    getPlanCourseList() {
        return http.get(baseUrl + "/student/plan/course/list", params);
    },

    // 查询计划基本情况
    getPlanOne: function(params) {
        return http.get(baseUrl + "/offline/train/planid", params);
    },

    // GET /offline/train/course 培训准备-课件
    getPlanCourseForPrepare: function(params) {
        return http.get(baseUrl + "/offline/train/course", params);
    },

    // GET /plan/offline/assess/list 查询考核人等
    getPlanLeaders: function(params) {
        return http.get(baseUrl + "/plan/offline/assess/list", params);
    },

    // 查询可用的考试列表
    getClassroomList: function(params) {
        return http.get(baseUrl + "/offline/train/class", params);
    },

    // 现场培训记录新增 POST /plan/offline/record/add
    addOfflineRecord: function(params) {
        return http.post(baseUrl + "/plan/offline/record/add", params);
    },

    // 现场培训记录修改, 目前用于结束 /plan/offline/record/mod
    modOfflineRecord: function(params) {
        return http.put(baseUrl + "/plan/offline/record/mod", params);
    },

    // 现场培训学员列表, 分页
    getStudentPage: function(params) {
        return http.put(baseUrl + "/plan/offline/student/page", params);
    },

    // 现场培训单个记录
    getOfflineRecordOne: function(params) {
        return http.get(baseUrl + "/plan/offlinerecord/one", params);
    },
};
