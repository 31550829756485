import http from "@tibi/http";
import { HudGlobal } from "../HudGlobal";
import userUtil from "../userUtil";
import GlobalHelper from "../GlobalHelper";
import router from "../../router";
import LocalStorageDBManger from "../../db/LocalStorageDBManger";
import { gotoHomeList } from "../../views/middle-ware/utils/ControlRouter";

const baseUrl = process.env.VUE_APP_API_HOUR_BASE_URL;
let loginBaseUrl = process.env.VUE_APP_API_SSO_URL;
let __isHasAlert = false;

let config = {
    baseUrl: baseUrl,
    token: true,
    tokenKey: "token",
    withCredentials: window.location.protocol === "https",
    tokenRefreshUrl: loginBaseUrl + "/token/refresh?offlineStudy=offline",
    tokenExpireTip: function(res) {
        if (!window.tokenExpiredTip) {
            window.tokenExpiredTip = true;
            // access token过期
            __isHasAlert = true;
            const message = res.data.description ? res.data.description : "页面已失效, 请重新从管理页面进入现场学习! 是否立即关闭页面";
            HudGlobal.showAlertConfirmMessage(message).then((res) => {
                __isHasAlert = false;
                userUtil.removeUserData(GlobalHelper.closeWindowEvent);
            });
        }
    },
};

http.init(config);

export default {
    get: function(url, param, opts) {
        const that = this;
        const newOptions = this.decorateRequestOptions(opts);
        return new Promise((resolve, reject) => {
            http.once(config)
                .get(url, param, newOptions)
                .then((res) => {
                    that.handleRequestSuccessResponse(resolve, res);
                })
                .catch((err) => {
                    that.handleRequestFailResponse(resolve, err);
                });
        });
    },
    post: function(url, param, opts) {
        const that = this;
        const newOptions = this.decorateRequestOptions(opts);
        return new Promise((resolve, reject) => {
            http.once(config)
                .post(url, param, newOptions)
                .then((res) => {
                    that.handleRequestSuccessResponse(resolve, res);
                })
                .catch((err) => {
                    that.handleRequestFailResponse(resolve, err);
                });
        });
    },
    put: function(url, param, opts) {
        const that = this;
        const newOptions = this.decorateRequestOptions(opts);
        return new Promise((resolve, reject) => {
            http.once(config)
                .put(url, param, newOptions)
                .then((res) => {
                    that.handleRequestSuccessResponse(resolve, res);
                })
                .catch((err) => {
                    that.handleRequestFailResponse(resolve, err);
                });
        });
    },

    delete: function(url, param) {
        const that = this;
        return new Promise((resolve, reject) => {
            http.once(config)
                .delete(url, param)
                .then((res) => {
                    that.handleRequestSuccessResponse(resolve, res);
                })
                .catch((err) => {
                    that.handleRequestFailResponse(resolve, err);
                });
        });
    },
    /**
     * 装饰请求的options
     */
    decorateRequestOptions(options) {
        const newOptions = {
            headers: {
                mapType: "GD", // GD 高德  BD 百度  TX 腾讯  目前是固定值 GD 2023-11-09 lzb
            },
        };
        // 加密
        Object.assign(newOptions, options);
        return newOptions;
    },
    //====================================请求响应统一处理==============================//
    /**
     * 请求成功处理
     */
    handleRequestSuccessResponse(resolve, response) {
        let vue = HudGlobal.getVue();
        // 服务器断开-  rqmei 20230616
        if (response && response.hasOwnProperty("code") && response.code == 900) {
            const message = response.description || "系统升级中，请稍后再试，恢复后可继续当前操作！";
            HudGlobal.showWarningWithMessage(message, 5000);
            return;
        }
        // 多端登录,不同的处理
        if (response.hasOwnProperty("code") && response.code == 405) {
            // vue.$alert.closeAll();
            const message = response.description ? response.description : "您已在其它登录, 确定关闭页面";
            if (__isHasAlert) {
                return;
            }
            __isHasAlert = true;
            vue.$alert(message, {
                showClose: false,
                cancelButtonText: "重新登录", // 2020/07/02 需求改动 fbz
            })
                .then((res) => {
                    __isHasAlert = false;
                    userUtil.logout().then((res) => {
                        GlobalHelper.closeWindowEvent();
                    });
                })
                .catch((err) => {
                    __isHasAlert = false;
                    userUtil.logout().then((res) => {
                        const urlPath = LocalStorageDBManger.getDataWithLocalStorage("fromLogin");
                        if (urlPath && urlPath.length > 0) {
                            router.push(urlPath);
                        } else {
                            gotoHomeList({});
                        }
                    });
                });
            return;
        }

        if (resolve) {
            resolve(response);
        }
    },
    /**
     * 请求失败处理
     */
    handleRequestFailResponse(resolve, error) {
        let message = null;
        // 网络离线 - lzb 20230525
        if (error.statusCode === 700 && error.code == 8100) {
            HudGlobal.showWarningWithMessage(error.description, 5000);
            return;
        }
        // 请求异常、或者响应异常 - rqmei 20230616
        if (error.statusCode === 700 && (error.code == 8000 || error.code == 8001)) {
            message = "网络连接失败";
            return;
        }
        // 请求取消
        if (error.statusCode === 700 && error.code == 3) {
            console.warn("重复请求被取消");
            return;
        }
        // 服务器断开-  rqmei 20230616
        if (error.statusCode == 900) {
            message = "系统升级中，请稍后再试，恢复后可继续当前操作！";
            HudGlobal.showWarningWithMessage(message, 5000);
            return;
        }

        HudGlobal.showErrorWithMessage(error.description || "请求网络失败");
    },
};
