/*
 * @Author: fbz
 * @Date: 2021-08-25 14:38:48
 * @LastEditors: fbz
 * @LastEditTime: 2021-09-15 11:04:16
 */
import hourHttp from "../../util/http/hourHttp";
import http from "../../util/http/studentHttp";
const baseUrl = process.env.VUE_APP_API_HOUR_BASE_URL;

/**
 * 学时通信
 */
export default {
  /*
        130 131, 查询现场学习返回状态码
        80222 现场学习记录标识不存在
        80223 现场学习教室未选择
        80224 计划不存在
        80225 计划不能学习
        80226 学习库未创建
        80229 学员未签到
     */
  // 学时系统更新（签到，签退，抽验，视频进度等）
  hoursAddOrMod(params) {
    return hourHttp.put(baseUrl + "/hours/study/mod", params);
  },

  /**
   * 创建新的学习库 - 多端学习
   * @param params
   * @returns {*}
   */
  addOfflineRecordOfMoreWindow: function(params) {
    return hourHttp.post(baseUrl + "/mongo/offline/lib/add", params);
  },

  // 学时查询用户列表
  getOfflineUserList(params) {
    return hourHttp.get(baseUrl + "/mongo/offline/user/List", params);
  },

  // 学时查询培训管理人列表
  getOfflineLeaderList(params) {
    return hourHttp.get(baseUrl + "/mongo/offline/leader/list", params);
  },

  // 现场学习开始学习人脸验证
  checkOfflineToStart(params) {
    return hourHttp.post(baseUrl + "/mongo/offline/start/validate", params);
  },
  // 现场学习权限结果查询
  checkOfflinePermission(params) {
    return hourHttp.post(baseUrl + "/mongo/offline/start/validate", params);
  },

  // 获取现场相关的参数
  getOfflineParams(params) {
    return hourHttp.get(baseUrl + "/hours/parameter/one", params);
  },

  // 培训开始准备
  addTrainStart(params) {
    return hourHttp.post(baseUrl + "/mongo/train/start/add", params);
  },

  // 取消培训开始
  delTrainStart(params) {
    return hourHttp.delete(baseUrl + "/mongo/train/start/del", params);
  },
  /**
   * 可签退列表
   * @param {*} params
   */
  signinList: function(params) {
    return hourHttp.get(baseUrl + "/mongo/offline/student/signin/list", params);
  },

  // lzb 教室查询经纬度
  getClassroomOne:function(params) {
    return http.get(baseUrl + "/org/classroom/one", params);
  },
  
};

/**
 * 播放次数统计接口 -  T-Z0941课件管理开源节流改善 yxl 20241125
 * @param {} params
 * @returns
 */
export const postCourseStatic = (params) => {
  return http.post(baseUrl + "/course/view/increment", params);
};
